<template>
  <v-dialog
    v-model="show"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar :color="options.color" dark dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
      <v-card-actions class="pt-0">
        <v-btn @click.native="cancel" color="grey" text>{{ options.cancelButtonText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click.native="agree" color="primary darken-1" text>{{
          options.acceptButtonText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <confirm ref="confirm"></confirm>
   *
   * Call it:
   * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <confirm ref="confirm"></confirm>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$confirm = this.$refs.confirm.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'primary',
        width: 290,
        zIndex: 200,
        cancelButtonText: 'Anuluj',
        acceptButtonText: 'Zatwierdź',
      },
    }),
    computed: {
      show: {
        get() {
          return this.dialog;
        },
        set(value) {
          this.dialog = value;
          if (value === false) {
            this.cancel();
          }
        },
      },
    },
    methods: {
      open(title, message, options = {}) {
        let tmp = {
          color: options.color ? options.color : 'primary',
          width: options.width ? options.width : 290,
          zIndex: options.zIndex ? options.zIndex : 200,
          cancelButtonText: options.cancelButtonText ? options.cancelButtonText : 'Anuluj',
          acceptButtonText: options.acceptButtonText ? options.acceptButtonText : 'Zatwierdź',
        };
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, tmp);
        this.dialog = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>
